import { useEffect, useState } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { useAuthState } from "react-firebase-hooks/auth"
import { app, auth, db, functions } from "https://framer.com/m/config-1Ws6.js"

export const useStore = createStore({
    isLoggedIn: false,
    isPro: false,
})

export const useAuth = () => {
    const [user, loading, error] = useAuthState(auth)
    const [store, setStore] = useStore()

    useEffect(() => {
        if (user) {
            setStore({
                isLoggedIn: true,
            })

            const getClaims = async () => {
                await user.getIdToken(true)
                const token = await user.getIdTokenResult()

                if (token.claims.stripeRole === "pro") {
                    setStore((old) => {
                        return {
                            ...old,
                            isPro: true,
                        }
                    })
                } else {
                    setStore((old) => {
                        return {
                            ...old,
                            isPro: false,
                        }
                    })
                }
            }

            getClaims()
        } else {
            setStore({
                isLoggedIn: false,
                isPro: false,
            })
        }
    }, [user])

    return { user, ...store, loading, error }
}
